.breadcrumb {
  font-size: 1.25rem;
  color: $azureRadiance;
  margin-bottom: 0;
  padding: 0;
  font-weight: 400;
  &.active {
    color: $firefly;
  }
}
