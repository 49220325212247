// Separated colors variables to share with styled-components

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

// Gray/White Colors https://projects.invisionapp.com/d/main/default/#/console/20638399/436668967/preview
$catskillWhite: #fbfcfd !default;
$whiteLilac: #f7f9fc !default;
$athensGray: #ebeef3 !default;
$mischka: #d2d6dc !default;
$darkMischka: #c4c9d1 !default;
$darkenMischka: #b5bcc6 !default;
$grayChateau: #9fa6b3 !default;
$stateGray: #75869a !default;
$oxfordBlue: #344456 !default;
$firefly: #102338 !default;
$darkFirefly: #2a3746 !default;
$darkerFirefly: #212b36 !default;

// Colors from style-guide https://projects.invisionapp.com/d/main/default/#/console/20638399/436668967/preview
$azureRadiance: #007aff !default;
$darkAzureRadiance: #006ee6 !default;
$darkenAzureRadiance: #0062cc !default;
$lightAzureRadiance: #e5f1ff !default;
$solitude: #1c8986 !default;
$orangePeel: #ff9f00 !default;
$serenade: #fff5e5 !default;
$redOrange: #ff3333 !default;
$fairPink: #ffeaea !default;
$mountainMeadow: #14a877 !default;
$aquaHaze: #80a1a6 !default;

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$purpleLight: #9884FF !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$primary: $azureRadiance !default;
$primaryLight: $lightAzureRadiance !default;
$primaryHover: $darkAzureRadiance !default;
$primaryActive: $darkenAzureRadiance !default;

$success: $mountainMeadow !default;
$lightSuccess: #e7f2ef !default;
$danger: $redOrange !default;
$lightDanger: #f8d7da !default;
$warning: $orangePeel !default;
$lightWarning: $serenade !default;
$info: $cyan !default;
$light: $gray-100 !default;

$secondary: $gray-600 !default;
$dark: $firefly !default;

:export {
  white: $white;
  gray100: $gray-100;
  gray200: $gray-200;
  gray300: $gray-300;
  gray400: $gray-400;
  gray500: $gray-500;
  gray600: $gray-600;
  gray700: $gray-700;
  gray800: $gray-800;
  gray900: $gray-900;
  black: $black;
  catskillWhite: $catskillWhite;
  whiteLilac: $whiteLilac;
  athensGray: $athensGray;
  mischka: $mischka;
  darkMischka: $darkMischka;
  darkenMischka: $darkenMischka;
  grayChateau: $grayChateau;
  stateGray: $stateGray;
  oxfordBlue: $oxfordBlue;
  firefly: $firefly;
  darkFirefly: $darkFirefly;
  darkerFirefly: $darkerFirefly;
  azureRadiance: $azureRadiance;
  darkAzureRadiance: $darkAzureRadiance;
  darkenAzureRadiance: $darkenAzureRadiance;
  lightAzureRadiance: $lightAzureRadiance;
  solitude: $solitude;
  orangePeel: $orangePeel;
  serenade: $serenade;
  redOrange: $redOrange;
  fairPink: $fairPink;
  mountainMeadow: $mountainMeadow;
  aquaHaze: $aquaHaze;
  blue: $blue;
  indigo: $indigo;
  purple: $purple;
  purpleLight: $purpleLight;
  pink: $pink;
  red: $red;
  orange: $orange;
  yellow: $yellow;
  green: $green;
  teal: $teal;
  cyan: $cyan;
  primary: $primary;
  primaryLight: $primaryLight;
  primaryHover: $primaryHover;
  primaryActive: $primaryActive;
  success: $success;
  lightSuccess: $lightSuccess;
  danger: $danger;
  lightDanger: $lightDanger;
  warning: $warning;
  lightWarning: $lightWarning;
  info: $info;
  light: $light;
  secondary: $secondary;
  dark: $dark;
}