.badge {
  color: $white;
}

.badge-light {
  color: $gray-700;
}

.card-header {
  background: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table thead th {
  background: $white;
  white-space: nowrap;
  vertical-align: bottom;
}

select .was-validated .form-control:invalid,
.form-control.is-invalid {
  background-position: center right calc(0.75em + 0.1875rem);
}

.modal-title {
  font-size: 1.125rem;
}

.form-group {
  margin-bottom: 24px;
}

.form-label {
  display: flex;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1rem;
  color: $oxfordBlue;
  margin-bottom: 8px;
}

.form-control {
  border-radius: 2px;
  padding: 10px 16px;
  font-size: 0.8125rem;
  line-height: 1rem;
  color: $firefly;
  height: auto;
  &:focus {
    outline: 0;
    border-color: $primary;
    box-shadow: none;
  }
  &::placeholder {
    color: $grayChateau;
  }
}

.modal-content {
  border: 0;
  border-radius: 0.25rem;
}

.breadcrumb {
  .breadcrumb-item {
    &:first-child {
      cursor: pointer;
      color: $primary;
      &::before {
        content: '<-';
        margin-right: 0.5rem;
      }
    }
    &:only-child {
      cursor: auto;
      color: $firefly;
      &::before {
        content: '';
        margin-right: 0;
      }
    }
  }
}

.nav-tabs {
  border: none;
  .nav-item {
    margin-right: 1rem;
    .nav-link {
      border: none;
      background-color: transparent;
      color: $stateGray;
      &.active {
        color: $primary;
        border-bottom: 2px solid $primary;
      }
    }
  }
}

.invalid-feedback.error-visible-no-validation {
  display: block;
}

select.form-control.is-valid {
  background-image: none;
}

select.form-control.is-invalid {
  background-image: none;
}
