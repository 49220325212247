@import './bootstrap/variables';
@import './shared/colors.module';

.react-datepicker__header {
  background-color: $gray-200;
  border-bottom: 1px solid $border-color;
}

.react-datepicker__day--selected {
  background-color: $primary;
}

.react-datepicker__day--selected:hover {
  background-color: $primary;
}

.react-datepicker__day--keyboard-selected {
  background-color: $primary;
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: $primary;
}

.react-datepicker__close-icon::after {
  background-color: $primary;
}
.react-datepicker__input-container input {
  padding: $input-padding-y $input-padding-x;
  border: $border-width solid $input-border-color;
  background: $input-bg;
  border-radius: $input-border-radius;
  font-size: $font-size-base;
  line-height: $line-height-base;
}

.react-datepicker__input-container input {
  width: 100%;
}
