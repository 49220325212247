@import './bootstrap/variables';
@import './shared/colors.module';

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
  max-height: 30rem;
  overflow: auto;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 38px;
  width: 100%;
  border: 1px solid $gray-200;
  background-color: $white;
  font-weight: 300;
  font-size: 1rem;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__input {
  display: block;
  width: 100%;
  height: $input-height;
  padding: $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 0.5rem 1.25rem;
  border-bottom: 1px solid $gray-400;
}

.react-autosuggest__suggestion--highlighted {
  background-color: $light;
  color: $white;
}
