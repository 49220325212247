.cm-focused {
  outline: none !important;
}

.cm-gutters,
.cm-placeholder,
.cm-line {
  font-family: Inter !important;
  font-size: 14px !important;
}

.cm-gutters {
  background: #f7f9fc !important;
  color: #102338 !important;
  font-weight: 500 !important;
  border-right: none !important;
}

.cm-placeholder {
  color: #9fa6b3 !important;
  font-weight: 400 !important;
}

.cm-line {
  padding-left: 5px !important;
}

.cm-content {
    padding-top: 10px !important;
}